import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../modules/auth'

const ContactCard = ({ contact }: any) => {
  const { currentUser }= useAuth()
  const navigate = useNavigate()

  const NavigationHandler = async (id: string) => {
    navigate(`/dashboard/47821104/my-contacts/update/${id}`)
  }

  const getInitials = (firstName: string | undefined | null, lastName: string | undefined | null) => {
    const firstInitial = firstName?.charAt(0).toUpperCase() || '';
    const lastInitial = lastName?.charAt(0).toUpperCase() || '';
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <div className="contact-card mt-3">
      {contact.student_contacts.profile_url ? (
        <img
          src={`${contact.student_contacts.profile_url}`}
          alt='profile'
          className='rounded-circle'
        />
      ) : (
        <div
          className='no-pic'
          style={{
            height: '100px',
            width: '100px',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            fontSize: '40px', 
            fontWeight: 'bold' 
          }}
        >
          {getInitials(
            contact.student_contacts.first_name,
            contact.student_contacts.last_name
          )}
        </div>
      )}
      <div className="contact-information">
        <div className="d-flex">
          <div className="fw-bold me-2">Name:</div>
          <div className='card-text'>{contact.student_contacts.first_name}</div>
        </div>
        <div className="d-flex">
          <div className="fw-bold me-2">Email:</div>
          <div className='card-text'>{contact.student_contacts.email}</div>
        </div>
        <div className="d-flex">
          <div className="fw-bold me-2">Relationship:</div>
          <div className='text-capitalize card-text'>{contact.relationship}</div>
        </div>
        <div className="d-flex">
          <div className="fw-bold me-2">Phone:</div>
          <div className='text-capitalize card-text'>{contact.student_contacts.phone}</div>
        </div>
      </div>
      {contact.type !== 'account_holder' ? (
        <Button variant='primary' type='button' onClick={() => NavigationHandler(contact.contact_id)}>
          Update Contact
        </Button>
      ) : (
        <Button variant="primary" type="button" onClick={() => navigate(`/profile/${currentUser?.currentClient}`)}>
          Update Contact
        </Button>
      )}
    </div>
  )
}

export default ContactCard
