import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import { getAllProgram, getAllTypeForm } from "../../network/api"
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import { currencyFormat } from '../../utils/funcHelpers';
import './EnrolledDashboard.scss'
import ApplicationStatusModal from '../modules/application/components/modal/ApplicationStatusModal';
import AddDiscountModal from './AddDiscountModal';
import EditDiscountModal from './EditDiscountModal';
import EditProgram from './EditProgram';
import ScrollArrow from './ScrollDownArrow';

interface TypeformData {
  id: number;
  form_id: string;
  response_type: string;
  name: string;
  overview: string;
  category: string;
}

const EnrolledDashboard = ({ data, refetch }: any) => {
  const navigate = useNavigate()
  const location = useLocation();
  const {currentUser} = useAuth()
  const [getContactPrograms, setContactPrograms] = useState<number[]>([]);
  const [getFormsByProgram, setFormsByProgram] = useState<{ [key: number]: number[] }>({});

  const [getCompletedPrograms, setCompletedPrograms] = useState<{ [key: string]: boolean }>({});
  const [getIncompleteForms, setIncompleteForms] = useState<{ [key: string]: string | null }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getTypeformData, setTypeformData] = useState<TypeformData[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalConfirmationApproveDeny, setShowModalConfirmationApproveDeny] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState({ success: '', error: ''});
  const [modalMessage, setModalMessage] = useState('');
  const [getAction, setAction] = useState('');

  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [getCurrentStatus, setCurrentStatus] = useState<string>()
  const [isError, setIsError] = useState<string | null>(null);
  const [savedProgress, setSavedProgress] = useState<any>(null)

  const [formData, setFormData] = useState({
    studentUUID: null,
    programUUID: null,
    formID: null,
  });

  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const [hasAdmin, setHasAdmin] = useState<any>(null)

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()
  }, []);
  
  useEffect(() => {
    //clear storage
    localStorage.removeItem('multiStepFormData');
    localStorage.removeItem('scholarshipCodes')
    localStorage.removeItem('currentStep');
    localStorage.removeItem('selectedCampus');
    sessionStorage.removeItem('selectedPaymentType');
    sessionStorage.removeItem('savedCreditCardInfo');
    sessionStorage.removeItem('savedBankAccountInfo');
    sessionStorage.removeItem('selectedStudent');
    sessionStorage.removeItem('selectedAppType');
    sessionStorage.removeItem('program_uuid');
    sessionStorage.removeItem('selectedExtras')
    sessionStorage.removeItem('tnc')
    sessionStorage.removeItem('tuition_plan')
    sessionStorage.removeItem('student_contact_id')
    sessionStorage.removeItem('typeform_data')
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch typeform data
        const { data: typeformData } = await getAllTypeForm();
        const categoryForms = typeformData.filter((form: { category: string; }) => form.category === "application");
  
        const studentIds = data.flatMap((entry: { traveler_info: { student_contacts: any, id: any; }; }) =>
          entry.traveler_info?.student_contacts?.id ? [entry.traveler_info.student_contacts.id] : []
        );

        const formIds = categoryForms.map((form: { id: any; }) => form.id);

        const formsForStudent = studentIds.reduce(
          (acc: { [key: string]: number[] }, studentId: number) => {
            acc[studentId] = formIds;
            return acc;
          },
          {}
        );
  
        setTypeformData(categoryForms)
        setFormsByProgram(formsForStudent); 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [data, refetch]);

  useEffect(() => {
    const fetchContactProgramsAndActiveForms = async () => {
      setIsLoading(true);
  
      try {
        // Fetch contact programs
        const contactProgramsPromise = Promise.resolve(data.flatMap((entry: { program: any[]; }) => entry.program.map(p => p.selected_program.id)));
        // Fetch active forms
        const activeFormsPromise = getAllProgram().then(response => response.data.filter((form: any) => form.isActive));
        const [contactPrograms, activeForms] = await Promise.all([contactProgramsPromise, activeFormsPromise]);
  
        // Process active forms based on fetched contact programs
        const contactProgramsSet = new Set(contactPrograms);
        const filteredActiveForms = activeForms.filter((form: { program_id: number; }) => contactProgramsSet.has(form.program_id));
        const formsByProgram = filteredActiveForms.reduce((acc: { [x: string]: any[] }, form: { program_id: string | number, form_id: any }) => {
          if (!acc[form.program_id]) {
            acc[form.program_id] = [];
          }
          acc[form.program_id].push(form.form_id);
          return acc;
        }, {});
        setContactPrograms(contactPrograms);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchContactProgramsAndActiveForms();
  }, [data]);

  useEffect(() => {
    const checkFormCompletion = async () => {
      if (Object.keys(getFormsByProgram).length === 0) return;
    
      setIsLoading(true);
      const completedStatuses: { [key: string]: boolean } = {};
      const incompleteFormsMap: { [key: string]: string | null } = {};
    
      try {
        const promises = data.map(async (item: any) => {
          const student_uuid = item.traveler_info.student_contacts.uuid;
          const getCompletedForms = item.typeform_data
                .filter((item: any) => item.response_type === 'Submitted - Needs Review')
                .map((item: any) => item.form_id)

          const studentForms = getFormsByProgram[student_uuid] || [];
          const isCompleted = await checkIndividualForms(student_uuid, studentForms);

          return Promise.all(item.program.map(async (program: any) => {
            const program_id = program.selected_program.id;
            // const programForms = getFormsByProgram[program_id] || [];
            // const isCompleted = await checkIndividualForms(student_uuid, programForms);
            completedStatuses[program_id] = isCompleted.completed;
            incompleteFormsMap[program_id] = isCompleted.firstIncompleteForm;

            const applicationStatusName = program?.application_status

            const matchedTypeFormIds = getTypeformData
            .filter((form) => getCompletedForms.includes(form.id))
            .map((form) => form.id);

            const uniqueFormIds = new Set(matchedTypeFormIds);
            
            if(applicationStatusName === 'Incomplete - Deposit Paid' && uniqueFormIds.size === 3
            ) {
              await checkCompletedForms(program.program_uuid, 'Received - Needs Review')
            }
            
          }));
        });
    
        await Promise.all(promises);
        setCompletedPrograms(completedStatuses);
        setIncompleteForms(incompleteFormsMap);
      } catch (error) {
        console.error('Error in checkFormCompletion:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const checkCompletedForms = async (program_uuid: any, selectedStatusValue: any) =>{
      try {
        setIsLoading(true)

        const data = { program_uuid: program_uuid, selectedStatusValue}
        await axiosPrivate.post('/api/contactprogram-updateApplicationStatus', data);
    
      } catch (error: any) {
        setIsError(error.message)
      } finally {
        refetch()
        setIsLoading(false)
      }
    }
    
    const checkIndividualForms = async (student_uuid: string, studentForms: number[]) => {
      let allFormsCompleted = true;
      let firstIncompleteForm: any | null = null;
    
      for (const formId of studentForms) {
        try {
          const response = await axiosPrivate.get('/api/typeform-validation', {
            params: { student_uuid, form_id: formId },
          });
    
          if (!response.data.isCompleted) {
            allFormsCompleted = false;
            if (!firstIncompleteForm) {
              firstIncompleteForm = formId;
            }
          }
        } catch (error) {
          console.error('Error checking form completion', error);
          allFormsCompleted = false;
          break;
        }
      }
      return { completed: allFormsCompleted, firstIncompleteForm };
    };

    if (!isRefetch){
      checkFormCompletion();
    }

  }, [data, getFormsByProgram]);

  const handleShowModal = async (student_uuid: any, program_uuid: any, form_id: any, status: string) => {
    setSelectedStatus(status);
    setCurrentStatus(status)
    setShowModal(true)
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleShowModalConfirmation = (student_uuid: any, program_uuid: any, form_id: any, message: string) => {
    setModalMessage(message);
    setShowModalConfirmation(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleShowModalConfirmationApproveDeny = (student_uuid: any, program_uuid: any, form_id: any, action: string, message: string) => {
    setModalMessage(message);
    setAction(action);
    setShowModalConfirmationApproveDeny(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  
  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalConfirmation(false);
    setShowModalConfirmationApproveDeny(false);
  };

  const handleDelete = async (program_uuid: string) => {
    if (window.confirm("Are you sure you want to delete this program?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(`/remove-application-status/${program_uuid}`);

        if (response.status === 200) {
          alert(response.data.message); 
          window.location.reload();  
        }
      } catch (error) {
        console.error('Error while deleting:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  
  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmitStatusChange = async (student_uuid: any, program_uuid: any, form_id: any) => {
    if (selectedStatus === getCurrentStatus) {
      setIsError(`The form status is already set to '${getCurrentStatus}'. No changes will be made.`)

      setTimeout(() => {
        setIsError('')
      }, 3000)

      return;
    }

    try {
      const response = await axiosPrivate.get('/api/typeform-updateStatus', {
        params: { student_uuid, program_uuid, form_id, selectedStatus},
      });

      if (response.data.isUpdated) {
        setIsRefetch(true)
        refetch()
      }
      
    } catch (error) {
      setIsError('An error occurred while submitting the form: ' + error)
    }

    handleCloseModal()
  };

  const handleSubmitRequest = async(student_uuid: any, program_uuid: any, form_id: any, message: string, account_holder_uuid?: string) => {
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, type: message, account_holder_uuid}
      const response = await axiosPrivate.post('/api/typeform-request', data);

      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
        setIsRefetch(true)
        refetch()
      }
      
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while submitting the form: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproveAndDenyRequest = async(student_uuid: any, program_uuid: any, form_id: any, action: any, message: string) =>{
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, action}
      if (message === 'Approve'){
        const response = await axiosPrivate.post('/api/typeform-approveRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})
          setIsRefetch(true)
          refetch()
        }
      } else {
        const response = await axiosPrivate.post('/api/typeform-denyRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})
          setIsRefetch(true)
          refetch()
        }
      }
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while processing the request: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const navigateForms = async (student_uuid: string, id: any, program_uuid: string, form_id: string, completedForms: any[]) => {
    setIsRefetch(false)
    try {
      setIsLoading(true);
      const data  = getTypeformData

      const formIdToFormId = data.reduce((acc: { [key: number]: string }, form: any) => {
        acc[form.id] = form.form_id;
        return acc;
      }, {});

      const matchedForm = data.find((form: any) => form.id === form_id);

      if (!matchedForm) {
        console.error('No matching form_id found.')
        return;
      }

      const formsForProgram = getFormsByProgram[id] || []
      const completedFormsSet = new Set(completedForms.map(id => Number(id)));
      const filterIncompleteForms = formsForProgram.filter((formId: number) => !completedFormsSet.has(formId));
      const currentForm = matchedForm.form_id
      const remainingForms = filterIncompleteForms
        .map(formId => formIdToFormId[formId])
        .filter(form => form && form !== currentForm)

      navigate(`/dashboard/${currentUser?.currentClient}/forms/${currentForm}`, { replace: true, state: {
        student_uuid: student_uuid,
        program_uuid: program_uuid,
        form_id: currentForm,
        remaining_forms: remainingForms,
        auth: currentUser?.currentClient,
        rawTypeformData: data
      }})
    } catch (error) {
      console.error("Error handling navigation:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handlePayNow = () => {
    navigate(`/billing-and-invoice/${currentUser?.currentClient}`)
  }

  const handlePayDeposit = (program_uuid: string) => {
    navigate(`/application/${currentUser?.currentClient}/pay-deposit/${program_uuid}`)
  }

  return (
    <div style={{ fontSize: 15 }}>

      {data.map((item: any, index: number) => {
        const student_uuid = item.traveler_info.student_contacts.uuid
        const studentName = `${item.traveler_info.student_contacts.first_name} ${item.traveler_info.student_contacts.last_name}`

        const getCompletedForms = item.typeform_data
          .filter((item: any) => item.response_type === 'Submitted - Needs Review')
          .map((item: any) => item.form_id)

return (
          <div key={index}>
          {item.program.map((program: any, idx: number) => {
              if (program?.application_status === 'Removed') {
                return null;
              }
              const campusName = program?.selected_program.programData.campus
              const tuitionName = program?.student_type
              const sessionName = `${program?.selected_session?.session_name} (${moment.utc(program.selected_session.start_date).format('MM/DD/YYYY')} - ${moment.utc(program.selected_session.end_date).format('MM/DD/YYYY')})`
              const programName = program.selected_program.name
              const applicationStatusName = program?.application_status
              const latestBalance = program?.ledger_transaction ? program?.ledger_transaction[0]?.balance : 0;
              const getPayment = program?.ledger_transaction ? program?.ledger_transaction.filter((item: { transaction_type: string; }) => ['payment', 'revoked'].includes(item.transaction_type)) : [];
              const totalRefund = program?.ledger_transaction ? program?.ledger_transaction.filter((item: any) => ['refund'].includes(item.transaction_type)).reduce((sum: any, ledger: any) => sum + ledger.paid, 0) : [];
              const totalPayment = getPayment.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
              // const latestDue = latestBalance !== 0 && program?.due_schedules ? program?.due_schedules : null;  
              const totalPaidandRefund = totalPayment - totalRefund
              const getAllPayable = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => !['payment', 'surcharge', 'revoked', 'discount', 'scholarship', 'removed'].includes(item.transaction_type)) : [];
              const getAllDiscounts = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => [ 'discount', 'scholarship'].includes(item.transaction_type)) : [];
              const overallPayment = getAllPayable.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
              const allDiscounts = getAllDiscounts.reduce((total: number, transaction: { paid: number; }) => total + Math.abs(transaction.paid), 0);
              const totalBalanceDue = (overallPayment - (totalPayment + allDiscounts));
              const latestDue = Math.max(totalBalanceDue, 0) !== 0 && program?.due_schedules ? program?.due_schedules : null;  
              const hasTPP = getAllPayable.some((item: any) => item.description === 'Tuition Protection Plan');
              const deadline = moment(program.created_at).add(14, 'days').format('MMMM DD, YYYY')
              const uniqueForm = Array.from(
                new Map(getTypeformData.map((form) => [form.form_id, {
                  id: form.id,
                  form_id: form.form_id,
                  name: form.name,
                  overview: form.overview
                }])).values()
              );
              
              const scholarshipTransactions = program.ledger_transaction.filter((ledger: any) => [ 'discount', 'scholarship'].includes(ledger.transaction_type));

              return (
              <div className='card card-body my-3' key={idx}>
                <div className='d-flex gap-3' style={{ alignItems: 'center' }}>
                  <div className='d-flex flex-column gap-3'>
                    <span className='fs-3 text-center'>{studentName}</span>
                    <div className='d-flex flex-column' style={{ background: "#ccc", padding: '5px' }}>
                      <span>{campusName}:</span>
                      <span className='text-capitalize'>{tuitionName} Student</span>
                      <span>{sessionName}</span>
                      <span>{programName}</span>
                    </div>
                    <div style={{ background: "#f5c310", padding: '5px'}}>Application Status: {applicationStatusName || 'Incomplete - Deposit Paid'}</div>
                    {hasAdmin && (scholarshipTransactions.length > 0) && (
                    <>
                      <tr>
                        <td style={{ fontWeight: 600 }}>
                          Scholarships and Discounts
                        </td>
                      </tr>
                      {scholarshipTransactions.map((ledger: any, index: number) => (
                        <tr key={index} className='card p-3'>
                          <div className='d-flex'>
                            <td>{ledger.description}</td>
                            <td>({currencyFormat.format(ledger.paid)})</td>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <EditDiscountModal program_uuid={program.program_uuid} ledger_id={ledger.id} refetch={refetch} />
                            {/* <Button variant='danger'>Delete</Button> */}
                          </div>
                        </tr>
                      ))}
                    </>
                  )}
                    <div className="card shadow-sm mb-4">
                      <div className="card-body">
                        <h5 className="card-title">Payment Status</h5>
                        <p className="card-text">Total Paid: <strong>{currencyFormat.format(totalPaidandRefund)}</strong></p>  
                        <p className="card-text">Outstanding Balance: <strong>{currencyFormat.format(totalBalanceDue)}</strong></p>
                        {latestDue?.[0]?.balance !== undefined && latestDue?.[0]?.due_date && (
                          <p className="text-muted">Next Payment Due: {moment.utc(latestDue[0]?.due_date).format('D MMMM YYYY')} - {currencyFormat.format(latestDue[0]?.balance ?? 0)}</p>
                        )}
                      {program?.application_status === 'Incomplete - Deposit Due' ? 
                      <Button 
                        className="btn btn-primary" 
                        onClick={() => handlePayDeposit(program?.program_uuid)}>
                        Pay Deposit
                      </Button> :
                      <Button 
                        className="btn btn-primary" 
                        onClick={handlePayNow}>
                        Pay Now
                      </Button>
                      }
                        {program?.application_status === 'Incomplete - Deposit Due' ? (
                          <Button 
                            className="btn btn-danger" 
                            style={{ width: '100px', marginLeft: '15px' }} 
                            onClick={() => handleDelete(program?.program_uuid)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  
                  <div className='flex-grow-1' style={{ alignSelf: 'flex-start'}}>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Section</th>
                        <th>Task Name</th>
                        <th>Overview</th>
                        <th>Status</th>
                        <th>Deadline</th>
                        <th style={{ width: 150 }}>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ border: '1px solid #dee2e6' }}>
                      {isLoading ? (
                        <tr>
                          <td colSpan={6} style={{ textAlign: 'center' }}>
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {uniqueForm.map((typeform: any, index: number) => {
                              const form_id = typeform.id; 
                              const correspondingTypeform = item.typeform_data.find((typeform: { form_id: any; }) => typeform.form_id === form_id);

                              const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                              const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;

                              let status = 'Incomplete';
                              if (isCompleted) status = 'Completed';
                              else if (isSubmitted) status = 'Submitted - Needs Review';

                              return (
                                <tr key={index}>
                                  {index === 0 && (
                                    <td rowSpan={getTypeformData.length} style={{ verticalAlign: "middle", background: '#ccc', textAlign: 'center' }}>
                                      Application Form
                                    </td>
                                  )}
                                  <td className='px-1'>{typeform?.name || 'N/A'}</td>
                                  <td>{typeform?.overview || 'N/A'}</td>
                                  <td 
                                    className={`${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                    onClick={status === 'Incomplete' ? () => navigateForms(
                                      student_uuid, 
                                      item.traveler_info.student_contacts.id, 
                                      program.program_uuid, 
                                      form_id!,
                                      getCompletedForms
                                    ) : undefined}
                                  >
                                    {status}
                                  </td>
                                  <td>{deadline}</td>
                                  <td style={{ textAlign: 'center'}}>
                                    {!hasAdmin && status === 'Incomplete' ? (
                                      <Button 
                                        variant="primary"
                                        style={{ width: '100px' }}
                                        onClick={() => navigateForms(
                                          student_uuid, 
                                          item.traveler_info.student_contacts.id, 
                                          program.program_uuid, 
                                          form_id!,
                                          getCompletedForms)}
                                      >
                                        Submit
                                      </Button>
                                    ) : (
                                      hasAdmin ? (
                                        <>
                                          <Button variant="secondary" style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }} 
                                            onClick={() =>{handleShowModal(student_uuid, program.program_uuid, form_id, status)}}>
                                            Form Status
                                          </Button>
                                          {correspondingTypeform && correspondingTypeform.action && (
                                            <>
                                              <Button variant="success" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, program.program_uuid, form_id, correspondingTypeform.action, 'Approve')}}>
                                                Approve
                                              </Button>
                                              <Button variant="danger" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, program.program_uuid, form_id, correspondingTypeform.action, 'Deny')}}>
                                                Deny
                                              </Button>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {correspondingTypeform && correspondingTypeform.action ? (
                                            <>
                                              {correspondingTypeform.action === 'resubmit' ? (
                                                <p style={{ fontSize: '14px' }}>Resubmit requested</p>
                                              ) : (
                                                <p style={{ fontSize: '14px' }}>Update requested</p>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <Button
                                                variant="link"
                                                style={{ textDecoration: 'underline', color: 'black' }}
                                                onClick={() => handleShowModalConfirmation(student_uuid, program.program_uuid, form_id, 'resubmit')}
                                              >
                                                Request to resubmit
                                              </Button>
                                              <hr style={{ width: '75%', margin: '10px auto' }} />
                                              <Button
                                                variant="link"
                                                style={{ textDecoration: 'underline', color: 'black' }}
                                                onClick={() => handleShowModalConfirmation(student_uuid, program.program_uuid, form_id, 'update')}
                                              >
                                                Request to update
                                              </Button>
                                            </>
                                          )}
                                        </>
                                      )
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </>
                      )}
                    </tbody>
                  </table>
                  <footer>
                    <div>
                      {hasAdmin && (
                        <ApplicationStatusModal
                          refetch={refetch}
                          program={program}
                        />
                      )}
                      {hasAdmin && (
                        <>
                          <AddDiscountModal
                            program_uuid={program.program_uuid}
                            refetch={refetch} 
                          />  
                        </>
                      )}
                      {hasAdmin && (
                        <>
                          <EditProgram programData={program} hasTPP={hasTPP}/>
                        </>
                      )}
                    </div>
                  </footer>
                  </div>
                </div>
              </div>
              )
          })}
          </div>
        )
      })}

      {/* Modal for Change Status */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop='static'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Form Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formStatus">
            <Form.Label>Select Form Status</Form.Label>
            <Form.Select value={selectedStatus} onChange={handleChangeStatus}>
              <option value="Incomplete">Incomplete</option>
              <option value="Submitted - Needs Review">Submitted - Needs Review</option>
              <option value="Completed">Completed</option>
            </Form.Select>
          </Form.Group>
          {isError && <div className="text-danger">{isError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {hasAdmin && (
            <Button variant="primary" onClick={() => { handleSubmitStatusChange(formData.studentUUID, formData.programUUID, formData.formID)}}>
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Modal Confirmation for User Resubmit/Update */}
      <Modal show={showModalConfirmation} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to request to {modalMessage}?
          {showSuccessMessage.success && (
            <Alert variant="success" className="mt-3">
              {showSuccessMessage.success}
            </Alert>
          )}
          {showSuccessMessage.error && (
            <Alert variant="danger" className="mt-3">
              {showSuccessMessage.error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={isLoading} onClick={() => { handleSubmitRequest(formData.studentUUID, formData.programUUID, formData.formID, modalMessage , currentUser?.uuid)}}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Confirmation for Admin Approve/Deny*/}
      <Modal show={showModalConfirmationApproveDeny} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {modalMessage} the request?
          {showSuccessMessage.success && (
            <Alert variant="success" className="mt-3">
              {showSuccessMessage.success}
            </Alert>
          )}
          {showSuccessMessage.error && (
            <Alert variant="danger" className="mt-3">
              {showSuccessMessage.error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" disabled={isLoading} onClick={() => {handleApproveAndDenyRequest(formData.studentUUID, formData.programUUID, formData.formID, getAction, modalMessage) }}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ScrollArrow />
    </div>
  );
};

export default EnrolledDashboard;