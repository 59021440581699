import * as Yup from 'yup'

export interface IPortalClients {
  portalClient: IPortalClient[]
}

export interface IPortalClient {
  id?: number
  value: string,
  name: string,
  abbreviation: string,
  reference_id?: string,
  status?: IStatus
  status_id?: number
}
export interface IStatus {
  id: number
  name: string
  type: string
}

export interface IAuth {
  api_token: string
  refreshToken?: string
  redirectUrl?: string
  email?: string
  first_name?: string
  last_name?: string
  uuid?: string
}

export interface IUserAddress {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface IUserCommunication {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface IUserEmailSettings {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface IUserSocialNetworks {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface NewIUser {
  id: number
  currentClient: string
  email: string
  firstName: string
  lastName: string
  role: string
  account_contacts : AccountContacts
}

export interface AccountContacts {
  address?: string;
  birth_date?: string;
  city?: string;
  country?: string;
  first_name?: string;
  gender?: string;
  grade_school?: string;
  id?: number;
  last_name?: string;
  nickname?: string;
  phone?: string;
  postal_code?: string;
  pronouns?: string;
  school_of_attending?: string;
  sex_assigned_at_birth?: string;
  state_province?: string;
  t_shirt_size?: string;
}


export interface IUser {
  id: number
  username?: string
  password?: string | undefined
  email: string
  firstName: string
  lastName: string
  fullname?: string
  avatar?: string
  clientNames: string[]
  currentClient: string
  currentOpportunity: number | null | undefined
  currentClientInfo: IPortalClient | undefined
  userclient: IUserClient
  account_contacts: AccountContacts
  primary_parent: IRelationInfo[]
  students: IRelationInfo[]
  uuid: string

 

  occupation?: string
  companyName?: string
  phone?: string
  role: Array<string> | string
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: ''
  emailSettings?: IUserEmailSettings
  auth?: IAuth
  communication?: IUserCommunication
  address?: IUserAddress
  socialNetworks?: IUserSocialNetworks
  flightPlan?: boolean
}

export interface IUserClient {
  id: number
  created_at: string
  updated_at: string
  loginuser_id: number
  first_name: string
  middle_name: string
  last_name: string
  phone: string
  school: string
  shirt_size: string
  date_of_birth: string
  address1: string
  address2: string
  city: string
  state: string
  zipcode: string
  country: string
  citizenship: string
  fax: string
  mobile: string
  account_owner: string
  account_name: string
  gender: string
  genderDescription: string
  sexAssignedAtBirth: string
  pronouns: string
  pronounsDescription: string
}

export interface IRole {
  id: number
  role_name: string
  created_at: string
  updated_at: string
}
export interface IRelationInfo {
  id: number
  email: string
  status: string
  isPrimary: boolean | null
  role_id: number
  created_at: string
  updated_at: string
  created_by: null
  opportunity_active: null
  client_id: string
  userclient: IUserClient[]
  role: IRole
}

export interface IResgister {
  firstName: string
  lastName: string
  email: string
  acceptTerms: boolean
}

export interface IResetToken {
  hash: string | undefined
  token: string | undefined
}
export interface IResetTokenData {
  message: string
  user: string
}

export interface IResetPassword extends IResetToken {
  password: string
}

//========== Create Account ======

export interface ICreateAccount {
  firstName: string
  lastName: string
  email: string
  acceptTerms: boolean
  role: string
  middleName: string
  clientName: [string] | null
  address1: string
  address2: string
  city: string
  state: string
  zipcode: string
}

export interface ISwitchProgram {
  clientToSwitch: string,
  token: string
}

export const createAccountSchemas = [
  Yup.object({
    role: Yup.string().oneOf(['student', 'teacher']).required('Please choose an account type'),
    acceptTerms: Yup.bool()
      .isTrue('You must accept the terms and conditions')
      .required('You must accept the terms and conditions'),
  }),
  Yup.object({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    clientName: Yup.mixed()
      .when('isArray', {
        is: Array.isArray,
        then: Yup.array().of(Yup.string()),
      })
      .required('Please choose a client'),
    firstName: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('First name is required'),
    lastName: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Last name is required'),
    address1: Yup.string().required().label('Address 1'),
    address2: Yup.string().label('Address 2'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    zipcode: Yup.string()
      .matches(/^\d{5}$/, 'Must be 5 digits')
      .required()
      .label('Zipcode'),
  }),
]
export const initsCreateAccount: ICreateAccount = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  middleName: '',
  clientName: null,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  acceptTerms: false,
}

