import moment from 'moment';
import { useState, useEffect } from 'react';
import './SessionPage.scss';
import { useParams } from 'react-router-dom';

interface Session {
  startDate: string;
  endDate: string;
}

const SessionPage = ({ formData, setFormData }: any) => {
  const [selectedSession, setSelectedSession] = useState<Session | null>(formData.selectedSession || null);
  const { campus } = useParams<{ campus: string }>();
  const decodedProgram = campus ? decodeURIComponent(campus) : '';
  useEffect(() => {
    if (selectedSession) {
      setFormData((prevData: any) => ({ ...prevData, selectedSession, decodedProgram}));
    }
  }, [selectedSession, setFormData, decodedProgram]);

  function calculateDuration(startDate: string, endDate: string): string {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));
    const days = duration.asDays();
    return `${Math.round(days + 1)} days`;
  }

  const handleCardClick = (session: Session) => {
    const newSelectedSession = selectedSession === session ? null : session;
    setSelectedSession(newSelectedSession);
  };

  return (
    <div style={{ marginTop: 45 }}>
    <h2 className='my-5 mx-3'>{decodedProgram}: {formData?.selectedProgram?.name}</h2>
      <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '30px',
      }}
    >
      {formData?.selectedProgram?.sessions?.length > 0 ? (
        <>
          {formData.selectedProgram.sessions.map((item: any, index: number) => (
            <div
              key={index}
              className={`session-list card mb-3 mx-3 ${selectedSession === item ? 'selected' : ''}`}
              onClick={() => handleCardClick(item)}
              style={{ cursor: 'pointer', width: '100%' }}
            >
              <div className="card-body">
                <div>
                  <strong>{item.sessionName}</strong>
                </div>
                <div>
                  <strong>Session date:</strong>{' '}
                  {moment.utc(item.start_date).format('MM/DD/YYYY')} -{' '}
                  {moment.utc(item.end_date).format('MM/DD/YYYY')}
                </div>
                <div>
                  <strong>Duration:</strong> {calculateDuration(item.start_date, item.end_date)}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        ''
      )}
    </div>
    </div>
  );
};

export default SessionPage;
